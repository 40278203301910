@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    -webkit-touch-callout: none; /* Safari Touch */
  }

  *:focus {
    outline: none !important;
  }

  input,
  button {
    @apply appearance-none;
  }
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local(""), url("/fonts/poppins-v15-latin-100.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-100.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local(""),
    url("/fonts/poppins-v15-latin-100italic.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-100italic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local(""), url("/fonts/poppins-v15-latin-200.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-200.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local(""),
    url("/fonts/poppins-v15-latin-200italic.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-200italic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(""), url("/fonts/poppins-v15-latin-300.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-300.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local(""),
    url("/fonts/poppins-v15-latin-300italic.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-300italic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("/fonts/poppins-v15-latin-regular.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("/fonts/poppins-v15-latin-italic.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-italic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(""), url("/fonts/poppins-v15-latin-500.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-500.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local(""),
    url("/fonts/poppins-v15-latin-500italic.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-500italic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(""), url("/fonts/poppins-v15-latin-600.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-600.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local(""),
    url("/fonts/poppins-v15-latin-600italic.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-600italic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("/fonts/poppins-v15-latin-700.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-700.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(""),
    url("/fonts/poppins-v15-latin-700italic.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-700italic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local(""), url("/fonts/poppins-v15-latin-800.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-800.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: local(""),
    url("/fonts/poppins-v15-latin-800italic.woff2") format("woff2"),
    url("/fonts/poppins-v15-latin-800italic.woff") format("woff");
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""),
    url("/fonts/playfair-display-v22-latin-regular.woff2") format("woff2"),
    url("/fonts/playfair-display-v22-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(""),
    url("/fonts/playfair-display-v22-latin-600.woff2") format("woff2"),
    url("/fonts/playfair-display-v22-latin-600.woff") format("woff");
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 900;
  src: local(""),
    url("/fonts/playfair-display-v22-latin-900.woff2") format("woff2"),
    url("/fonts/playfair-display-v22-latin-900.woff") format("woff");
}

.signature {
  font-family: Brush Script MT, Brush Script Std, cursive;
}

/* the cookie consent button requires a bit of work - this fixes the glaring issues */
#ot-sdk-btn-floating.ot-floating-button button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #464fd4 !important;
  border-radius: 8px;
}

#ot-sdk-btn-floating.ot-floating-button {
  left: 16px !important;
  bottom: 16px !important;
}

.discover-masonry-grid {
  display: flex;
  margin-left: -12px; /* gutter size offset */
  width: auto;
}
.discover-masonry-grid_column {
  padding-left: 12px; /* gutter size */
  background-clip: padding-box;
}

/* React-Calendar library styles */
.react-calendar {
  width: 330px;
  max-width: 100%;
  background: white;
  border: 1px solid #cbd5e1;
  border-radius: 12px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  padding: 15px;
  margin: 0 auto;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
}
.react-calendar__navigation__label__labelText {
  font-size: 16px;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__month-view__days__day abbr {
  font-size: 14px;
  color: #545cea;
  font-weight: 700;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  /* color: #d10000; */
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  padding: 9px;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  margin: 2px;
}

.react-calendar__tile:disabled {
  opacity: 0.5;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar .react-calendar__tile--now {
  background: #f2f8ff;
  border-radius: 100%;
  border: 2px solid #d9dffc;
  margin-top: -2px;
  margin-bottom: -2px;
}

.react-calendar .react-calendar__tile--active {
  background: #d5e4f6;
  border-radius: 100%;
  border: 1px solid blue;
  margin-top: -2px;
  margin-bottom: -2px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  color: #545cea;
  font-weight: 700;
}

.react-calendar__navigation {
  margin-bottom: 10px;
}

.react-calendar__navigation__label {
  font-size: 18px;
  color: #545cea;
  font-weight: 700;
}

.react-calendar__navigation__prev-button {
  font-size: 24px;
  color: #545cea;
  font-weight: 700;
}

.react-calendar__navigation__next-button {
  font-size: 24px;
  color: #545cea;
  font-weight: 700;
}
.react-calendar__navigation__next-button:disabled {
  color: #cdcdcd;
}
.react-calendar__navigation__prev-button:disabled {
  color: #cdcdcd;
}

.react-calendar__tile--booked {
  pointer-events: none;
  position: relative;
  opacity: 0.5;
}

.react-calendar__tile--booked::after {
  content: "";
  position: absolute;
  top: 70%;
  left: 44%;
  width: 5px;
  height: 5px;
  background-color: #18c15c;
  border-radius: 50%;
  transform: translate(0%, 0%);
}

.react-calendar__month-view .react-calendar__tile--available {
  background-color: #f2f8ff;
  color: #545cea;
  border: 2px solid #d9dffc;
  border-radius: 5px;
}

.react-calendar__tile--not-available {
  pointer-events: none;
  opacity: 0.5;
}

.react-calendar .react-calendar__tile--selected {
  background: #545cea;
  border: 2px solid #d9dffc;
  padding: 0px;
  border-radius: 5px;
  overflow: visible !important;
}

.react-calendar .react-calendar__tile--selected abbr {
  color: white;
}

.icon-container {
  position: absolute;
  top: -8px;
  right: -2px;
  height: 16px;
  width: 16px;
}

/* override Flicker active pagination bullet color (orange) with brand color */
.flicking-pagination-bullet-active {
  background-color: #545cea !important;
}
